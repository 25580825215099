import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ViewTitle from '../../components/ViewTitle';
import { SubjectCard, ProfileWidget, PubsCards } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { getSubjects } from '../../redux/slices/subjects';
import Skeleton from '@material-ui/lab/Skeleton';
import { getNewSubjectsProgress } from '../../redux/slices/newProgress';
import { getPubsCards } from '../../redux/slices/offers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { openModal } from '../../redux/slices/modals';

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subjects, isLoading } = useSelector((state) => state.subjects);
  const { newsubjectsProgress } = useSelector((state) => state.newProgress);
  const { informations } = useSelector((state) => state.user);
  const { pubsCards, pubsCardsStatus } = useSelector((state) => state.offers);

  console.log('informations here', informations);
  useEffect(() => {
    if (!subjects && informations?.division_id) {
      dispatch(getSubjects());
    }

    if (!newsubjectsProgress && informations?.division_id) {
      dispatch(getNewSubjectsProgress());
    }
    if (
      !informations?.hasPhoneParent &&
      (informations?.country_id === 1 || informations?.country_id === null)
    ) {
      dispatch(openModal('add-parent-phone'));
    }
  }, [informations?.division_id]);

  useEffect(() => {
    if (pubsCardsStatus === 'idle' && pubsCards?.length < 1) {
      dispatch(getPubsCards());
    }
  }, [pubsCards]);

  if (isLoading) {
    return (
      <div className="ta-row">
        <div className="ta-column right-margin subjects-container">
          <div className="child-full subjects-list">
            {[...Array(8)].map(() => (
              <Skeleton variant="rect" width={179} height={149} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="full-page">
      <ViewTitle title={t('Matières')} />
      <div className="ta-row">
        {isLoading !== true ? (
          <div className="ta-column right-margin subjects-container">
            {subjects && subjects.length > 0 ? (
              <div className="child-full subjects-list">
                {subjects
                  .filter((subject) => subject.active === true)
                  .sort((a, b) => (parseInt(a.position) > parseInt(b.position) ? 1 : -1))
                  .map((subject) => {
                    const newProgress =
                      newsubjectsProgress &&
                      newsubjectsProgress.length > 0 &&
                      newsubjectsProgress.find((p) => p.subject_id == subject.id);
                    const newSubjectProgress = newProgress
                      ? Math.round((newProgress?.nbCompleted / subject?.subjectVideos) * 100)
                      : 0;
                    return (
                      <SubjectCard
                        subject={subject}
                        newSubjectProgress={newSubjectProgress}
                        isLoading={isLoading}
                      />
                    );
                  })}
              </div>
            ) : (
              <CircularProgress color="inherit" />
            )}
          </div>
        ) : (
          <div className="ta-column right-margin subjects-container">
            <div className="child-full subjects-list">
              {subjects &&
                subjects.map((subject) => <Skeleton variant="rect" width={179} height={149} />)}
            </div>
          </div>
        )}
        <div className="ta-column fixed right">
          <ProfileWidget mini />

          {pubsCards
            ?.slice()
            ?.sort((a, b) => a.position - b.position)
            ?.map((card) => {
              return (
                <PubsCards
                  key={card?.id}
                  className={card?.type}
                  icon={card?.icon_url}
                  text={card?.text_image_url || card?.text}
                  isText={card?.text}
                  modal={card?.video_link != null}
                  path={card?.video_link || card?.url_link}
                  bg={card?.background_url}
                  visible={card?.visible}
                  iconPosition={card?.icon_position}
                  color={card?.color}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Index;
