import { createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';
import {closeModal} from "./modals";
import {enqueueSnackbar} from "./notifier";
import i18n from "../../services/i18n";

// Slice
const slice = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    recordings: [],
    reasons: [],
    reactions: 0,
    currentLesson: null,
    courseError: false,
    isLoading: false,
    isVideoLoading: false,
    isContentLoading: false,
    isBookmarkLoading: false,
    isReportLoading: false,
    otp: null,
    error: false,
    reportError: '',
    bookmarked: '',
    bookmarkedMessage: false,
    report: null,
  },
  reducers: {
    startLoading: (state) => {
      state.courses = '';
      state.isLoading = true;
    },
    startVideoLoading: (state) => {
      state.isVideoLoading = true;
    },
    startContentLoading: (state) => {
      state.isContentLoading = true;
      state.courseError = false;
      //state.currentLesson = '';
    },
    startBookmarkLoading: (state) => {
      //state.bookmarked = '';
      state.isBookmarkLoading = true;
      state.bookmarkedMessage = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    coursesSuccess: (state, action) => {
      state.courses = action.payload;
      state.isLoading = false;
      state.bookmarked = '';
    },
    recordingsSuccess: (state, action) => {
      state.recordings = action.payload;
      state.isLoading = false;
      state.bookmarked = '';
    },
    GET_ENCRYPTED_VIDEO_OTP: (state, action) => {
      state.otp = action.payload;
      state.isVideoLoading = false;
    },
    contentSuccess: (state, action) => {
      state.currentLesson = action.payload.payload;
      state.bookmarked = '';
      state.isContentLoading = false;
    },
    courseHasError: (state, action) => {
      state.courseError = action.payload;
      state.isContentLoading = false;
    },
    bookmarkSuccess: (state, action) => {
      state.bookmarked = action.payload.payload;
      state.bookmarkedMessage = action.payload.message;
      state.isBookmarkLoading = false;
    },
    GetReactions: (state, action) => {
      state.reactions = action.payload;
    },
    startReportLoading: (state) => {
      state.report = '';
      state.reportError = '';
      state.isReportLoading = true;
    },
    reportSuccess: (state, action) => {
      state.report = action.payload;
      state.isReportLoading = false;
    },
    getReasons: (state, action) => {
      state.reasons = action.payload.payload;
    },
    hasReportError: (state, action) => {
      state.reportError = action.payload;
      state.isReportLoading = false;
    },
  },
});

const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;
export default slice.reducer;
// Actions
const {
  coursesSuccess,
  recordingsSuccess,
  startLoading,
  hasError,
  GET_ENCRYPTED_VIDEO_OTP,
  startVideoLoading,
  bookmarkSuccess,
  contentSuccess,
  courseHasError,
  startContentLoading,
  startBookmarkLoading,
  startReportLoading,
  GetReactions,
  reportSuccess,
  hasReportError,
  reactionSuccess,
  getReasons,
} = slice.actions;

export const getLessonContents = (subject_slug, chapter_slug) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/student/subjects/${subject_slug}/chapters/${chapter_slug}/contents`)
      .then((response) => dispatch(coursesSuccess(response.data.payload)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getRecordingContents = (subject_slug, chapter_slug) => async (dispatch) => {
  dispatch(startLoading());
  try {
    await api
      .get(`/student/subjects/${subject_slug}/chapters-recording/${chapter_slug}/contents`)
      .then((response) => dispatch(recordingsSuccess(response.data.payload)));
  } catch (e) {
    return dispatch(hasError(e.message));
  }
};

export const getCryptedVideoOtp = (subject_slug, chapter_slug, lesson_slug) => async (dispatch) => {
  dispatch(startVideoLoading());
  try {
    await api
      .get(`/user/subjects/${subject_slug}/chapters/${chapter_slug}/contents/${lesson_slug}/otp`)
      .then((response) => dispatch(GET_ENCRYPTED_VIDEO_OTP(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const getReactions = (lesson_id) => async (dispatch) => {
  try {
    await api
      .get(`${analyticsUrl}/reactions/${lesson_id}`)
      .then((response) => dispatch(GetReactions(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const sendReaction = (content_id, reaction_type) => async () => {
  try {
    await api.post(`${analyticsUrl}/reactions/`, { content_id, reaction_type }).then(() => {});
  } catch (e) {
    return console.error(e.message);
  }
};

export const toggleCompleted = (subject_slug, chapter_slug, lesson_slug) => async () => {
  try {
    await api
      .post(
        `/student/subjects/${subject_slug}/chapters/${chapter_slug}/contents/${lesson_slug}/complete`
      )
      .then(() => {});
  } catch (e) {
    return console.error(e.message);
  }
};

export const toggleBookmarked = (subject_slug, chapter_slug, lesson_slug) => async (dispatch) => {
  dispatch(startBookmarkLoading());
  try {
    await api
      .post(
        `/user/subjects/${subject_slug}/chapters/${chapter_slug}/contents/${lesson_slug}/bookmark`,
        {
          'chapter': chapter_slug,
          'content': lesson_slug,
        }
      )
      .then((response) => dispatch(bookmarkSuccess(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const content = (subject_slug, chapter_slug, lesson_slug) => async (dispatch) => {
  dispatch(startContentLoading());

  try {
    await api
      .get(`/student/subjects/${subject_slug}/chapters/${chapter_slug}/contents/${lesson_slug}`)
      .then((response) => dispatch(contentSuccess(response.data)));
  } catch (e) {
    if (e.response) {
      if (e?.response?.status === 401) {
        const adminID = localStorage.getItem('admin_user_id');
        if (adminID && adminID.includes('?redirectTo=RegisterPage')) {
          window.location = 'https://education-autrement.takiacademy.com/register';
          localStorage.removeItem('admin_user_id');
        } else {
          localStorage.clear();
          window.location = '/login';
        }
      }
      let message = '';
      message = e.response.data.message;
      return dispatch(courseHasError(message));
    }
  }
};

export const sendVideoReport = (content, reportReason, description) => async (dispatch) => {
  dispatch(startReportLoading());
  try {
    await api.post(`/video-reports`, { content, reportReason, description }).then((response) => {
      dispatch(reportSuccess(response.data));
      dispatch(closeModal('video-report-modal'));
      const message = response?.data?.message;
      dispatch(
          enqueueSnackbar({
            message: i18n.t(message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
          })
      );
    });
  } catch (e) {
    if (e.response) {
      let message = '';
      let key;
      if (e.response.data.errors) {
        for (key in e.response.data.errors) {
          message = e.response.data.errors[key][0];
        }
      } else {
        message = e.response.data.message;
      }
      dispatch(
          enqueueSnackbar({
            message: i18n.t(message),
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'warning',
            },
          })
      );
      return dispatch(hasReportError(message));
    }
  }
};
export const getReportReasons = () => async (dispatch) => {
  try {
    await api.get(`/report-reasons`).then((response) => dispatch(getReasons(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};
