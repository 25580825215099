import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';

import { Button } from '@material-ui/core';
import { UpdateParentRequests } from '../../../redux/slices/parentRequests';
import { useTranslation } from 'react-i18next';

const ParentRequestConfirmation = ({ id, open = true, handleClose, data, ...rest }) => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const language = i18n.language;

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleClose(id);
    if (data) {
      data.setApprovedRequest({ approved: null, id: null });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal "
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">
          {data?.approved === 1 ? t('Confirmer') : t('Rejeter')} {t('la demande parentale')}
        </span>
        <span className="close-btn" onClick={() => handleCloseModal()}>
          {t('Fermer')}
        </span>
      </DialogTitle>
      <DialogContent
        className={
          data?.approved === 1 ? 'confirm-parent-request ta-form' : 'reject-parent-request ta-form'
        }
      >
        <div className={language === 'ar' ? 'confirm-text confirm-arabic-text' : 'confirm-text'}>
          {t('Vous êtes sur le point de')}
          {data?.approved === 1 ? t('confirmer') : t('rejeter')}
          {t('la demande de')}
          <span className="parent-name"> {data?.name} </span>.. {t('Êtes-vous sûrs que')}
          <span className="parent-name"> {data?.name} </span>
          {data?.approved === 1 ? t('est') : t("n'est pas")}
          {t('votre parent?')}
        </div>

        <div className="ta-btns-group">
          <Button className="ta-btn btn-rounded btn-lg blue" onClick={() => handleCloseModal()}>
            {t('Annuler')}
          </Button>
          <Button
            className="ta-btn btn-rounded btn-lg parent-request-confirm-btn"
            onClick={() => {
              dispatch(UpdateParentRequests({ id: data?.id, approved: data?.approved }));
              handleClose(id);
            }}
          >
            {data?.approved === 1 ? t('Confirmer') : t('Rejeter')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ParentRequestConfirmation;
