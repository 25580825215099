import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modals: [
    {
      id: 'confirm-transfer-point-modal',
      open: false,
    },
    {
      id: 'confirm-start-exam-modal',
      open: false,
    },
    {
      id: 'video-report-modal',
      open: false,
    },
    {
      id: 'transfer-point-modal',
      open: false,
    },
    {
      id: 'convert-coins-modal',
      open: false,
    },
    {
      id: 'add-email-modal',
      open: false,
    },
    {
      id: 'verify-email-modal',
      open: false,
    },
    { id: 'parent-rquest-confirm-modal', open: false },
    {
      id: 'add-parent-phone',
      open: false,
    },
  ],
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModalSuccess: (state, action) => {
      const { id, data } = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      // eslint-disable-next-line no-param-reassign
      state.modals[index].open = true;
      // eslint-disable-next-line no-param-reassign
      state.modals[index].data = data;
    },
    closeModalSuccess: (state, action) => {
      const id = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      // eslint-disable-next-line no-param-reassign
      state.modals[index].open = false;
    },
  },
});

export default slice.reducer;
const { openModalSuccess, closeModalSuccess } = slice.actions;
export const openModal = (id, data) => async (dispatch) => {
  dispatch(openModalSuccess({ id, data }));
};

export const closeModal = (id) => (dispatch) => {
  dispatch(closeModalSuccess(id));
};
