import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import * as dayjs from 'dayjs';
import { Link, NavLink } from 'react-router-dom';

import history from '../../assets/img/icons/history.png';
import { Empty } from '../../components';

const Index = ({ cols, rows, bookmark, emptyComponent, className }) => {
  const { t } = useTranslation();
  const respoContEl = useRef(null);
  const tableContEl = useRef(null);
  const paginContEl = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tableContHeight, setTableContHeight] = useState(0);
  const [paginContHeight, setPaginContHeight] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateTableDemonsions = (taH, PaH) => {
    respoContEl.current.style.height = `${taH + PaH - 16}px`;
    paginContEl.current.style.marginTop = `${taH}px`;
  };

  useLayoutEffect(() => {
    setTableContHeight(tableContEl.current.clientHeight);
    setPaginContHeight(paginContEl.current.clientHeight);
    updateTableDemonsions(tableContHeight, paginContHeight);
  });

  return (
    <div className={`table-responsive ${className}`} ref={respoContEl}>
      <TableContainer className="table-container" ref={tableContEl} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols &&
                cols.length > 0 &&
                cols.map((col, index) => {
                  return <TableCell key={index}>{col.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows && rows.length > 0 ? (
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {cols &&
                      cols.length > 0 &&
                      cols.map((col, index) => {
                        return (
                          <TableCell key={index}>
                            {bookmark ? (
                              <Link
                                to={
                                  '/subjects/' +
                                  row.subjectSlug +
                                  '/chapters/' +
                                  row.chapterSlug +
                                  '/lessons/' +
                                  row.lessonSlug +
                                  '/content'
                                }
                                className="tab-nav-link"
                              >
                                {col.type && col.type === 'date'
                                  ? dayjs(row[col.accessor]).format('DD/MM/YYYY')
                                  : row[col.accessor]}
                              </Link>
                            ) : (
                              <div>
                                {row[col.accessor] && col.type && col.type === 'date'
                                  ? dayjs(row[col.accessor]).format('DD/MM/YYYY')
                                  : t(row[col.accessor])}
                              </div>
                            )}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(cols && cols.length) || 1} align={'center'}>
                  {
                    (emptyComponent = (
                      <Empty label={t('pas d historique')} icon={history} className="no-borders" />
                    ))
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        ref={paginContEl}
        // rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length || []}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('Rows per page')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')}  ${count}`}
      />
    </div>
  );
};

Index.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

export default Index;
