import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import constants from '../../utilities/constants';
import { useDispatch, useSelector } from 'react-redux';
import { me } from '../../redux/slices/user';
import { getAutocomplete } from '../../redux/slices/search';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import { MenuDropdown, MessagesDropDown, WalletWidget } from '../../components';
import Header from '../Sidebar/components/Header';
import SearchIcon from '@material-ui/icons/Search';
import { avatarByGender } from '../../utilities/methods';
import PopupsModal from '../../components/Popups/ModalPopus';
import RatingSession from '../../components/RatingSession';
import { getRatingSessions } from '../../redux/slices/sessions';
import useSettings from '../../hooks/useSettings';
import { useHistory } from 'react-router';
import CoinsBalance from '../../components/CoinsBalance';

const drawerWidth = constants.DRAWER_WIDTH;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      // width: `calc(100% - ${drawerWidth}px)`,
      // marginLeft: drawerWidth,
      width: '100%',
      marginLeft: 0,
      zIndex: 2000,
    },
    background: '#ffffff',
    color: '#000000',
    boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2) !important',
    zIndex: 2000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuButton2: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    border: '1px solid #d6d6da',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#70A2B8',
  },
  searchIconRtl: {
    direction: 'rtl',
    position: 'absolute',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    right: 0,
    padding: theme.spacing(2, 2),
    color: '#70A2B8',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '38ch',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}));

const Index = ({ handleDrawerToggle, mobileOpen }) => {
  const { i18n, t } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const { rateables } = useSelector((state) => state.sessions);
  const { informations, success } = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.user);
  const routerHistory = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (informations?.division_id) {
      dispatch(getRatingSessions());
    }
  }, [informations?.division_id]);
  const { autocomplete_result } = useSelector((state) => state.search);
  const profileCompleted = informations && informations?.completed;
  const editPath = '/mon-profil/edit';
  const location = useLocation();
  const adminId =
    !localStorage.hasOwnProperty('admin_user_id') ||
    localStorage.getItem('admin_user_id') == 'undefined' ||
    localStorage.getItem('admin_user_id') == null;
  const redirectUser =
    adminId &&
    informations &&
    !profileCompleted &&
    informations?.verified &&
    location.pathname !== editPath &&
    ((!informations?.phone && informations?.country_id == 1) || profileCompleted == false);

  const userDivisionId = informations && informations.division_id && !profileCompleted;

  const businessUser =
    informations &&
    informations.businessId &&
    profileCompleted &&
    !informations.birth_date &&
    location.pathname !== editPath &&
    !informations.institute_id &&
    !informations.state_id;
  const classes = useStyles();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [division, setDivision] = useState('');
  const [points, setPoints] = useState('0');
  const [avatar, setAvatar] = useState();
  const [userId, setUserId] = useState('');

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const { details } = useSelector((state) => state.user);

  const offerFreeId = 104;
  const offerFreeCondition = informations?.offers?.filter(
    (offer) => offer.group.id === offerFreeId //this condition is temporary for offre free//
  ).length;

  useEffect(() => {
    if (!informations) {
      dispatch(me());
    }
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  useEffect(() => {
    const event = new CustomEvent('popups-data', {
      detail: {
        ...details,
        currentUrl: window.location.origin + location.pathname + location.search,
      },
    });
    console.log('here event', event);
    window.dispatchEvent(event);
  }, [location, details]);

  useEffect(() => {
    if (autocomplete_result) {
      setOptions(autocomplete_result);
    }
  }, [autocomplete_result]);

  useEffect(() => {
    if (informations) {
      setName(informations.name);
      setLastName(informations?.last_name);
      setDivision(informations.division);
      setPoints(informations.points);
      setUserId(informations.id);
      setAvatar(avatarByGender(informations.avatar, informations.gender));
    }
  }, [informations]);

  useEffect(() => {
    if (redirectUser == true) {
      window.location = '/mon-profil/edit';
    }
  }, [redirectUser]);

  useEffect(() => {
    if (businessUser == true) {
      window.location = '/mon-profil/edit';
    }
  }, [businessUser]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
    }
  };
  useEffect(() => {
    if (!informations?.division_id && success) {
      routerHistory.push('/mon-profil/edit');
    }
  }, [success]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
  }, [value, inputValue]);

  const handleChange = (keyword) => {
    dispatch(getAutocomplete(keyword));
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => saveSettings({ sidebar: settings.sidebar === 'full' ? 'mini' : 'full' })}
          className={classes.menuButton2}
        >
          <MenuIcon />
        </IconButton>
        <Header handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
        <div className={classes.search + ' search-main'}>
          <div className={i18n.language === 'ar' ? classes.searchIconRtl : classes.searchIcon}>
            <SearchIcon />
          </div>
          <Autocomplete
            disabled={offerFreeCondition > 0}
            id="google-map-demo"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            className="searchbar"
            getOptionLabel={(option) => (typeof option === 'string' ? option.name : option.name)}
            filterOptions={(x) => x}
            options={options}
            noOptionsText={t('No options')}
            autoComplete
            groupBy={(option) => option?.subject_name}
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              window.location.reload();
            }}
            onInputChange={(event, newInputValue) => {
              if (event.target.value.length >= 3) {
                handleChange(event.target.value);
                setInputValue(event.target.value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('recherche')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                variant="outlined"
                fullWidth
              />
            )}
            renderOption={(option) => {
              return (
                <Link
                  className="autocopmlete-item"
                  to={`/subjects/${option.subject_slug}/chapters/${option.chapter_slug}/lessons/${option.content_slug}/content`}
                >
                  <span className="title">{option.name}</span>
                  <span className="subtitle">{option.subject}</span>
                </Link>
              );
            }}
          />
        </div>
        <div className={classes.grow} />
        {permissions?.eligibleUserForGift && (
          <CoinsBalance
            points={informations?.gift_point}
            giftCode={informations?.gift_code}
            inputValue={inputValue}
            hasFreeHours={permissions?.freeHours}
          />
        )}

        <WalletWidget
          points={points}
          inputValue={inputValue}
          hasFreeHours={permissions?.freeHours}
        />

        <MessagesDropDown userId={userId} />
        <MenuDropdown name={name} lastName={lastName} division={division} avatar={avatar} />
        <PopupsModal userId={userId} />
        {rateables &&
          rateables.map((reateable) => {
            return <RatingSession reateable={reateable} />;
          })}
      </Toolbar>
    </AppBar>
  );
};

Index.propTypes = {};

export default Index;
