import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { CardTitle, MyProgressList, ShowMoreLink } from '../../components';
import { getSubjectsWithProgress } from '../../redux/slices/subjects';
import { getSubjectsProgress } from '../../redux/slices/progress';
import { useDispatch, useSelector } from 'react-redux';
import { getNewSubjectsProgress } from '../../redux/slices/newProgress';

const Index = () => {
  const { t } = useTranslation();
  const { subjects_progress } = useSelector((state) => state.subjects);
  //const { subjectsProgress, isSubjectsProgressLoading } = useSelector((state) => state.progress);
  const { newsubjectsProgress, newisSubjectsProgressLoading } = useSelector(
    (state) => state.newProgress
  );
  const { informations } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [subjects, setSubjects] = React.useState([]);
  React.useEffect(() => {
    if (!subjects_progress && informations?.division_id) {
      dispatch(getSubjectsWithProgress());
    }
    if (!newsubjectsProgress && informations?.division_id) {
      dispatch(getNewSubjectsProgress());
    }
  }, [informations?.division_id]);
  React.useEffect(() => {
    if (subjects_progress) {
      setSubjects(subjects_progress || []);
    }
  }, [subjects_progress]);
  return (
    <Card className="ta-card my-progress-widget">
      <CardHeader title={<CardTitle title={t('ma progression')} />} />
      <CardContent>
        <MyProgressList
          subjects={subjects}
          //subjectsProgress={subjectsProgress}
          newSubjectsProgress={newsubjectsProgress}
        />
        <ShowMoreLink path="/subjects" />
      </CardContent>
    </Card>
  );
};

Index.propTypes = {
  subjects_progress: PropTypes.array,
  dispatch: PropTypes.func,
  subjects: PropTypes.array,
  setSubjects: PropTypes.func,
};

export default Index;
