import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../redux/slices/modals';
import { isAuthenticated } from '../../utilities/auth';
import ConfirmTransferPointsModal from '../common/Modal/ConfirmationTransferPoints';
import TransferPointsModal from '../common/Modal/TransferPoints';
import VideoReportModal from '../common/Modal/VideoReportModal';
import ConvertCoinsModal from '../common/Modal/ConvertCoinsModal';
import ParentRequestConfirmation from '../common/Modal/ParentRequestConfirmation';
import ConfirmStartExam from '../common/Modal/ConfirmStartExam';
import EmailModal from '../common/SessionModal/EmailModal';
import VerifyEmailModal from '../common/SessionModal/VerifyEmailModal';
import AddParentPhoneModal from '../common/Modal/AddParentPhoneModal';

const ModalsProvider = (props) => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const user = localStorage.getItem('token');
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return isAuthenticated(user) ? (
    <>
      <TransferPointsModal
        id="transfer-point-modal"
        open={modalState('transfer-point-modal', 'open')}
        data={modalState('transfer-point-modal', 'data')}
        handleClose={handleClose}
      />
      <VideoReportModal
        id="video-report-modal"
        open={modalState('video-report-modal', 'open')}
        data={modalState('video-report-modal', 'data')}
        handleClose={handleClose}
      />
      <ConfirmTransferPointsModal
        id="confirm-transfer-point-modal"
        open={modalState('confirm-transfer-point-modal', 'open')}
        data={modalState('confirm-transfer-point-modal', 'data')}
        handleClose={handleClose}
      />
      <ConvertCoinsModal
        id="convert-coins-modal"
        open={modalState('convert-coins-modal', 'open')}
        data={modalState('convert-coins-modal', 'data')}
        handleClose={handleClose}
      />

      <ParentRequestConfirmation
        id="parent-rquest-confirm-modal"
        open={modalState('parent-rquest-confirm-modal', 'open')}
        data={modalState('parent-rquest-confirm-modal', 'data')}
        handleClose={handleClose}
      />
      <ConfirmStartExam
        id="confirm-start-exam-modal"
        open={modalState('confirm-start-exam-modal', 'open')}
        data={modalState('confirm-start-exam-modal', 'data')}
        handleClose={handleClose}
      />
      <EmailModal
        id="add-email-modal"
        open={modalState('add-email-modal', 'open')}
        data={modalState('add-email-modal', 'data')}
        handleClose={handleClose}
      />
      <VerifyEmailModal
        id="verify-email-modal"
        open={modalState('verify-email-modal', 'open')}
        data={modalState('verify-email-modal', 'data')}
        handleClose={handleClose}
      />

      <AddParentPhoneModal
        id="add-parent-phone"
        open={modalState('add-parent-phone', 'open')}
        data={modalState('add-parent-phone', 'data')}
        handleClose={handleClose}
      />
    </>
  ) : null;
};

export default ModalsProvider;
