import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { TextField, Box, FormHelperText, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { addParentPhone } from '../../../redux/slices/user';

const AddParentPhoneModal = ({ id, open, handleClose, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isParentPhoneLoading } = useSelector((state) => state.user);
  const isMountedRef = useIsMountedRef();

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(id)}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-video-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <span className="label">{t('Ajouter un autre numéro')}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          {t('Fermer')}
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <Formik
          initialValues={{
            phone2: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            phone2: Yup.string()
              .matches(
                /^(2[0-9]{7}|4[0-9]{7}|5[0-9]{7}|9[0-9]{7})$/,
                t('Invalid phone number format')
              )
              .length(8, t('Phone number must be exactly 8 digits long'))
              .required(t('Phone number is required')),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(addParentPhone(values.phone2));

              if (isMountedRef.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            resetForm,
          }) => (
            <form noValidate onSubmit={handleSubmit} className="ta-form" {...rest}>
              <Typography variant="body2" color="textSecondary" className="parent-phone-text">
                {t('Veuillez ajouter un autre numéro de téléphone')}
              </Typography>
              <TextField
                className="input"
                error={Boolean(touched.phone2 && errors.phone2)}
                fullWidth
                helperText={touched.phone2 && errors.phone2}
                placeholder={t('Numéro de téléphone')}
                margin="normal"
                name="phone2"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.phone2}
                variant="outlined"
                size="small"
              />

              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box mt={2} className="ta-btns-group">
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="ta-btn primary btn-rounded btn-lg"
                >
                  {isParentPhoneLoading == 'loading' ? (
                    <CircularProgress style={{ color: 'white' }} />
                  ) : (
                    t('Ajouter')
                  )}
                </Button>
                <Button
                  disabled={isSubmitting || isParentPhoneLoading == 'loading'}
                  type="reset"
                  className="ta-btn btn-rounded btn-lg blue"
                  onClick={resetForm}
                >
                  {t('Annuler')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddParentPhoneModal;
